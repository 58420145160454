import { captureException } from '@sentry/nextjs';

import { GetBuildIdResponse } from '@/types/api';
import { useEffect } from 'react';
import { createGetFetcher } from '../api/client/fetchers';

const get = createGetFetcher('build ID');

export const useCheckBuildId = () => {
  useEffect(() => {
    const checkBuildId = async () => {
      try {
        const data = await get<GetBuildIdResponse>('/api/info/build-id');

        const clientBuildId = process.env.NEXT_PUBLIC_BUILD_ID;
        const serverBuildId = data.buildId;

        if (clientBuildId !== serverBuildId) {
          window?.location.reload();
        }
      } catch (error) {
        captureException(error);
      }
    };
    checkBuildId();
  });
};
