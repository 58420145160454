import { FieldSet, Record as AirtableRecord } from 'airtable';
import { AxiosError } from 'axios';

import { CreateSupportTicketRequest, HttpError } from '@/types/api';

import { api } from './axios';

export const createSupportTicket = async (
  req: CreateSupportTicketRequest
): Promise<AirtableRecord<FieldSet>> => {
  try {
    const response = await api.post<AirtableRecord<FieldSet>>(
      '/api/support/create',
      req
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to get create support ticket: ${axiosError.response?.data.message}`
    );
  }
};
