import * as amplitude from '@amplitude/analytics-browser';
import { Divider, Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CompanyMedal, MediaType } from '@prisma/client';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';

import { ANALYTICS_EVENTS } from '@/lib/constants/analyticsEvents';
import { Company, Media } from '@/types/api';
import { ProposalCompany } from '@/types/api/proposals';

import { CompanyAttributes } from '../proposals/pages/CompanyAttributes';
import { HighlightBadge } from '../proposals/pages/HighlightBadge';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { CottageMediaCarousel } from '../shared/default/CottageMediaCarousel';
import { MediaModal } from '../shared/default/CottageVideo';
import { ModalType, TwoColumnModal } from '../shared/modals/TwoColumnModal';
import { ProfileHeader } from './ProfileHeader';
import { SuperProBadge, SuperProBadgeType } from './SuperProBadge';

type CompanyProfileQuickViewProps = {
  company: ProposalCompany | Company;
  onClose: () => void;
  opened: boolean;
  projectMunicipality?: string;
};

export const CompanyProfileQuickView = ({
  company,
  opened,
  onClose,
  projectMunicipality,
}: CompanyProfileQuickViewProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  // Analytics
  useEffect(() => {
    if (company) {
      amplitude.track({
        event_type: ANALYTICS_EVENTS.COMPANY_QUICK_VIEW_MODAL_OPENED,
        event_properties: {
          companyId: company.id,
        },
      });
    }
  }, [company]);

  // Local state is used to manage transition from the quick view modal itself
  // to the media modal.
  const [
    isQuickViewModalOpened,
    { open: openQuickViewModal, close: closeQuickViewModal },
  ] = useDisclosure(false);
  const [isMediaModalOpened, { open: openMediaModal, close: closeMediaModal }] =
    useDisclosure(false);

  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(
    undefined
  );

  // The overall opened state of this component is managed by its parent
  // component via this effect. This approach allows the parent of this
  // component to not have to micromanage the opened state of the modals
  // in this component. Instead, the parent component only needs to control
  // whether the modal group is opened/closed, which leaves the logic of opening
  // and closing specific modals to this component.
  useEffect(() => {
    if (opened) {
      openQuickViewModal();
    } else {
      closeQuickViewModal();
      closeMediaModal();
    }
  }, [opened, openQuickViewModal, closeQuickViewModal, closeMediaModal]);

  if (!company && opened) {
    Sentry.captureMessage(`CompanyProfileQuickView has opened but company is 
      missing`);

    return <></>;
  }

  if (!company) {
    return <></>;
  }

  const companyTestimonials = company.company_testimonial;
  const handlePrevious = () => {
    setTestimonialIndex((prevIndex) =>
      companyTestimonials && companyTestimonials.length > 0
        ? prevIndex === 0
          ? companyTestimonials.length - 1
          : prevIndex - 1
        : 0
    );
  };

  const handleNext = () => {
    setTestimonialIndex((prevIndex) =>
      companyTestimonials && companyTestimonials.length > 0
        ? prevIndex === companyTestimonials.length - 1
          ? 0
          : prevIndex + 1
        : 0
    );
  };

  const handleMediaClicked = (media: Media) => {
    setSelectedMedia(media);
    closeQuickViewModal();
    openMediaModal();
  };
  const handleVideoModalClosed = () => {
    setSelectedMedia(undefined);
    openQuickViewModal();
    closeMediaModal();
  };

  return (
    <>
      <TwoColumnModal
        modalType={ModalType.WIDE}
        opened={isQuickViewModalOpened}
        onClose={onClose}
        allowReopen
        withClose
      >
        {!isMobile && (
          <TwoColumnModal.Media>
            <CottageMediaCarousel
              images={company.media ?? []}
              size={424}
              onMediaClicked={handleMediaClicked}
            />
          </TwoColumnModal.Media>
        )}

        <TwoColumnModal.Text>
          <Stack spacing="sm">
            {company.medal === CompanyMedal.SUPERPRO && (
              <SuperProBadge displayType={SuperProBadgeType.SPREAD} />
            )}
            <ProfileHeader
              company={company}
              displayWebsite
              hideMedal
              projectMunicipality={projectMunicipality}
            />
          </Stack>

          <CompanyAttributes
            company={company}
            projectMunicipality={projectMunicipality}
          />
          <Text c="blue.9">{company.about_info}</Text>
          {/* Badges block */}
          {company.company_derived_data_cache?.badges &&
            company.company_derived_data_cache?.badges.length > 0 && (
              <>
                <Divider />
                <Title order={5}>Badges</Title>
                <Group spacing="xxs">
                  {company.company_derived_data_cache?.badges.map((tag) => (
                    <HighlightBadge key={tag} highlightTag={tag} />
                  ))}
                </Group>
              </>
            )}
          {/* Testimonial block */}
          {companyTestimonials && companyTestimonials.length > 0 && (
            <>
              <Divider />
              <Group position="apart">
                <Title order={5}>
                  Testimonial{companyTestimonials.length > 1 ? 's' : ''}
                </Title>
                {companyTestimonials.length > 1 && (
                  <Group spacing="xxxxs">
                    <TbChevronLeft
                      size={24}
                      onClick={handlePrevious}
                      cursor="pointer"
                    />
                    <TbChevronRight
                      size={24}
                      onClick={handleNext}
                      cursor="pointer"
                    />
                  </Group>
                )}
              </Group>
              <Stack bg="blue.0" p="md" style={{ borderRadius: '4px' }}>
                <Text>{companyTestimonials[testimonialIndex].text}</Text>
                <Stack spacing="xxxxs">
                  <Text fw="bold" c="blue.9" fz="sm">
                    {companyTestimonials[testimonialIndex].reviewer_name}
                  </Text>
                  <Group position="apart" align="center">
                    <Text c="blue.6" fz="sm">
                      {companyTestimonials[testimonialIndex].reviewer_location}
                    </Text>
                  </Group>
                </Stack>
              </Stack>
            </>
          )}
        </TwoColumnModal.Text>
      </TwoColumnModal>
      <MediaModal
        media={selectedMedia}
        opened={isMediaModalOpened}
        onClose={handleVideoModalClosed}
        size={selectedMedia?.media_type === MediaType.VIDEO ? 'xl' : '100%'}
      />
    </>
  );
};
