import { AxiosError } from 'axios';

import { Company, GcCompany, HttpError } from '@/types/api';

import { api } from './axios';

export const company = async () => {
  try {
    const response = await api.get<Company>('/api/user/company');
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to fetch company for user with error: ${axiosError.response?.data.message}`
    );
  }
};

export const companyLookup = async (uuid: string) => {
  try {
    const response = await api.get<Company>(`/api/company/customers/${uuid}`);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to fetch company for user with error: ${axiosError.response?.data.message}`
    );
  }
};

export const projectGcCompanies = async (uuid: string) => {
  const route = `/api/projects/${uuid}/gcCompanies`;
  try {
    const response = await api.get<GcCompany[]>(route);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to fetch GC companies for project with error: 
      ${axiosError.response?.data.message}`
    );
  }
};
