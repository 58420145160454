import axios, { AxiosResponse } from 'axios';

import { deserializeDates } from '@/lib/apiUtils';

export const api = axios.create({
  headers: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

const stringToDateMiddleware = (
  response: AxiosResponse<Record<string, unknown> | Record<string, unknown>[]>
) => {
  if (response.data && typeof response.data === 'object') {
    response.data = deserializeDates(response.data);
  }
  return response;
};

// Add response interceptor
api.interceptors.response.use(stringToDateMiddleware, (error) => {
  return Promise.reject(error);
});
