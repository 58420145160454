import { createStyles } from '@mantine/core';

export enum zIndex {
  // Used for things that should sit on top of the carousel
  BASE_OVERLAY = 1,
  // Used for things that should sit on top of the modal. This is mostly for
  // things that use the portal.
  MODAL_OVERLAY = 1000,
  MODAL = 500,
  NAVIGATION = 400,
  ACTION_BAR = 300,
  OVERLAY_WRAPPER = 200, // not used but listed for documentation
}

export const useContainerStyles = createStyles((theme) => ({
  padding: {
    padding: theme.spacing.xl,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ padding: theme.spacing.md },
    },
  },
  paddingLg: {
    padding: theme.spacing.lg,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ padding: theme.spacing.sm },
    },
  },

  padding48: {
    padding: '48px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ padding: theme.spacing.xl },
    },
  },
  // These values are the negative equivalents of the padding defined above.
  negativeMarginX: {
    marginLeft: `-${theme.spacing.xl}`,
    marginRight: `-${theme.spacing.xl}`,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{
        marginLeft: `-${theme.spacing.md}`,
        marginRight: `-${theme.spacing.md}`,
      },
    },
  },
  gap: {
    gap: theme.spacing.xl,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ gap: theme.spacing.md },
    },
  },
  gapMd: {
    gap: theme.spacing.md,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ gap: theme.spacing.xxs },
    },
  },
  gapLg: {
    gap: theme.spacing.lg,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      gap: theme.spacing.sm,
    },
  },
  gapXxl: {
    gap: theme.spacing.xxl,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      ...{ gap: theme.spacing.xl },
    },
  },
}));
