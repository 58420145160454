import { Carousel } from '@mantine/carousel';
import { Box, MantineNumberSize, Paper, PaperProps } from '@mantine/core';
import { MediaType } from '@prisma/client';
import { PropsWithChildren } from 'react';

import { zIndex } from '@/lib/styleUtils';
import { Media } from '@/types/api';

import { VideoThumbnail } from './CottageVideo';

type CottageMediaCarouselProps = {
  images: Media[];
  size: MantineNumberSize;
  onMediaClicked?: (media: Media) => void;
  overlay?: React.ReactNode;
};

type ImageMediaSlideProps = {
  size: MantineNumberSize;
  url: string;
  onClick?: () => void;
} & PropsWithChildren &
  PaperProps;

export const ImageMediaSlide = ({
  size,
  url,
  children,
  onClick,
  ...props
}: ImageMediaSlideProps) => (
  <Paper
    p="xl"
    radius="sm"
    style={{
      width: size,
      height: size,
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      cursor: onClick ? 'pointer' : 'default',
    }}
    data-testid="company-media"
    onClick={() => onClick?.()}
    {...props}
  >
    {children}
  </Paper>
);

/**
 * A collection of media to be displayed in a carousel
 * @param onVideoThumbnailClicked A function that is called when media is
 * clicked. If this is passed, the play button for videos will be displayed.
 * @returns
 */
export const CottageMediaCarousel = ({
  images,
  size,
  overlay,
  onMediaClicked,
}: CottageMediaCarouselProps) => {
  const slides = images.map((image) => {
    switch (image.media_type) {
      case MediaType.IMAGE:
        return (
          <Carousel.Slide key={image.uuid}>
            <ImageMediaSlide
              size={size}
              url={image.url ?? ''}
              onClick={onMediaClicked ? () => onMediaClicked(image) : undefined}
            />
          </Carousel.Slide>
        );
      case MediaType.VIDEO:
        return (
          <Carousel.Slide key={image.uuid}>
            <VideoThumbnail
              media={image}
              onClick={onMediaClicked ? () => onMediaClicked(image) : undefined}
            />
          </Carousel.Slide>
        );
    }
  });

  // TODO (COT-3387): add a placeholder image for when there are no images
  if (images.length === 0) {
    return <></>;
  }

  return (
    // position relative constrains the overlay to the parent container's
    // dimensions
    <Box maw={size} pos="relative" style={{ aspectRatio: '1/1' }}>
      {overlay && (
        <Box
          pos="absolute"
          w="100%"
          h="100%"
          p="md"
          pb="50px"
          style={{ zIndex: zIndex.BASE_OVERLAY }}
        >
          {overlay}
        </Box>
      )}
      <Carousel
        withIndicators
        styles={{
          control: {
            border: '1px solid white',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              boxShadow: '3px 3px 12px rgba(0, 0, 0, 0.2)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            },
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
          indicator: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            boxSizing: 'content-box',
            margin: '0 2px',
            '&[data-inactive]': {
              opacity: '0.65',
            },
            '&:last-child, &:first-of-type': {
              width: '6px',
              height: '6px',
              transform: 'translate(+2px, +2px)',
              '&[data-active]': {
                width: '10px',
                height: '10px',
                transform: 'translate(0, 0)',
              },
            },
          },
        }}
      >
        {slides}
      </Carousel>
    </Box>
  );
};
