import * as amplitude from '@amplitude/analytics-browser';
import { Flex, Loader, Stack, Text } from '@mantine/core';
import { FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { CategoryType } from '@prisma/client';
import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import { TbAlertCircle, TbChecks, TbHelpCircle } from 'react-icons/tb';

import {
  CottageAlert,
  CottageSelect,
  CottageTextarea,
} from '@/components/shared/default';
import { createSupportTicket } from '@/lib/api/client/support';
import { ANALYTICS_EVENTS, PAGES } from '@/lib/constants/analyticsEvents';
import { Role } from '@/lib/constants/role';
import {
  generatePresignedDownloadUrls,
  uploadToDigitalOcean,
} from '@/lib/documentUtils';
import useCompany from '@/lib/hooks/useCompany';
import useProjects from '@/lib/hooks/useProjects';
import { useSupportCategories } from '@/lib/hooks/useSupportCategories';
import { getRole } from '@/lib/roleUtils';
import { splitName } from '@/lib/stringUtils';
import { CreateSupportTicketRequest } from '@/types/api';
import { DocumentType, UploadType } from '@/types/api/documents';

import BaseModal from './BaseModal';
import ActionButton from './buttons/ActionButton';
import DocumentUpload from './document/DocumentUpload';
import OkModal from './OkModal';

type SupportModalProps = {
  onClose: () => void;
  opened: boolean;
};

export const SupportModal = ({ opened, onClose }: SupportModalProps) => {
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [submittingTicket, setSubmittingTicket] = useState<boolean>(false);
  const [supportCategoryId, setSupportCategoryId] = useState<number>();
  const [supportFiles, setSupportFiles] = useState<FileWithPath[]>([]);

  const hasSetProjectRecord = useRef(false);

  const {
    data: session = {
      user: {
        email: '',
        roles: [],
        userId: '',
        name: '',
      },
    },
  } = useSession({ required: true });

  const {
    projects = [],
    loading: loadingProjects,
    error: errorProjects,
  } = useProjects();

  const {
    supportCategories = [],
    loading: loadingCategories,
    error: errorCategories,
  } = useSupportCategories();

  const role = getRole(session?.user?.roles ?? []);

  const { company } = useCompany();

  const supportTicketForm = useForm({
    initialValues: {
      category: '',
      subCategory: '',
      projectRecord: '',
      description: '',
    },
    validateInputOnBlur: true,
    validate: {
      category: (value) => (!value ? 'Category is required' : null),
      subCategory: (value) => (!value ? 'Sub-category is required' : null),
      description: (value) => (!value ? 'Description is required' : null),
    },
  });

  useEffect(() => {
    if (
      role === Role.CUSTOMER &&
      projects.length === 1 &&
      !hasSetProjectRecord.current
    ) {
      supportTicketForm.setFieldValue(
        'projectRecord',
        projects[0].airtableId ?? ''
      );
      hasSetProjectRecord.current = true;
    }
  }, [role, projects]);

  const resetForm = () => {
    // Reset form and state
    setSupportFiles([]);
    supportTicketForm.reset();
  };

  const formOnSubmit = supportTicketForm.onSubmit((values) => {
    const func = async () => {
      if (!session?.user.email) {
        throw new Error('User session does not have an email address.');
      }

      const [, categoryName] = values.category.split(':');
      try {
        setSubmittingTicket(true);
        const uploadedFiles = await uploadToDigitalOcean(
          supportFiles,
          UploadType.SUPPORT
        );
        const downloadUrls = await generatePresignedDownloadUrls(
          uploadedFiles.documents,
          UploadType.SUPPORT
        );

        const name = splitName(session.user.name ?? '');

        const ticket: CreateSupportTicketRequest = {
          projectRecordId: values.projectRecord,
          submitterEmail: session?.user.email,
          submitterType: role?.toLocaleUpperCase() || '',
          supportCategory: categoryName,
          supportDescription: values.description,
          customerFirstName: name.firstName,
          customerLastName: name.lastName,
          submitterCompany: company?.name,
          ticketAttachments: downloadUrls,
          supportSubCategory: values.subCategory,
        };

        amplitude.track({
          event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_SUBMIT,
          event_properties: {
            ...ticket,
          },
        });

        await createSupportTicket(ticket);
        setShowSuccessModal(true);
        setShowErrorBanner(false); // in case submitting again works

        resetForm();
      } catch (e) {
        amplitude.track({
          event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_FAILED,
          event_properties: {
            error: e,
          },
        });
        Sentry.captureException(e);
        setShowErrorBanner(true);
      } finally {
        setSubmittingTicket(false);
      }
    };
    func();
  });

  const projectsOptions = [
    {
      value: 'Not specific to a project',
      label: 'Not specific to a project',
      key: 0,
    },
  ];
  projects?.map((project, index) => {
    projectsOptions.push({
      value: project.airtableId ?? '',
      label: project.name ?? '',
      key: index + 1,
    });
  });

  const categoryOptions = supportCategories
    ?.filter(
      (supportCategory) => supportCategory.type === CategoryType.CATEGORY
    )
    .map((category, index) => ({
      value: `${category.id}:${category.name}`,
      label: category.name,
      key: index,
    }));
  categoryOptions.push({
    value: '0:Other',
    label: 'Other',
    key: categoryOptions.length,
  });

  if (errorProjects) {
    Sentry.captureException(errorProjects);
    amplitude.track({
      event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_FAILED,
      event_properties: {
        errorProjects,
      },
    });
  }
  if (errorCategories) {
    Sentry.captureException(errorCategories);
    amplitude.track({
      event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_FAILED,
      event_properties: {
        errorCategories,
      },
    });
  }

  return (
    <>
      <BaseModal
        opened={opened && !showSuccessModal}
        onClose={() => {
          resetForm();
          onClose();
        }}
        title="Cottage Support"
        subtitle="Tell us about the issue you're experiencing"
        icon={<TbHelpCircle />}
        iconColor="blue"
        size="lg"
        withCloseButton={loadingProjects || loadingCategories}
        isForm
        formOnSubmit={formOnSubmit}
        hasStickyActionBar
        primaryAction={
          <ActionButton
            type="submit"
            loading={submittingTicket}
            variant={submittingTicket ? 'subtle' : 'filled'}
            disabled={!supportTicketForm.isValid()}
          >
            {submittingTicket ? 'Submitting' : 'Submit Ticket'}
          </ActionButton>
        }
        secondaryAction={
          <ActionButton
            variant="subtle"
            onClick={() => {
              amplitude.track({
                event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_CLOSED,
                event_properties: {
                  button: 'Cancel',
                },
              });
              supportTicketForm.reset();
              setShowSuccessModal(false);
              setSubmittingTicket(false);
              void onClose();
            }}
          >
            Cancel
          </ActionButton>
        }
      >
        {loadingProjects || loadingCategories ? (
          <Flex direction="column" align="center" p="md">
            <Loader />
          </Flex>
        ) : (
          <Stack>
            {showErrorBanner && (
              <CottageAlert
                title="Support ticket failed to send"
                icon={<TbAlertCircle />}
                color="red"
              >
                Please try again.
              </CottageAlert>
            )}
            <CottageSelect
              aria-label="Issue Category*"
              placeholder="Select a category"
              label="Issue Category*"
              clearable
              searchable
              data={categoryOptions}
              style={{
                overflow: 'visible',
              }}
              {...supportTicketForm.getInputProps('category')}
              onChange={(value: string) => {
                if (value) {
                  const [id] = value.split(':');
                  setSupportCategoryId(parseInt(id));
                  supportTicketForm.setFieldValue('category', value);
                  supportTicketForm.setFieldValue('subCategory', '');
                  if (value === '0:Other') {
                    supportTicketForm.setFieldValue('subCategory', 'Other');
                  }
                } else {
                  // Handle the case where the user clears the selection
                  setSupportCategoryId(undefined);
                  supportTicketForm.setFieldValue('category', '');
                  supportTicketForm.setFieldValue('subCategory', '');
                }
              }}
            />
            <CottageSelect
              aria-label="Sub-Category"
              placeholder="Select a sub-category"
              label="Sub-Category*"
              disabled={
                !supportTicketForm.values.category ||
                supportTicketForm.values.category === '0:Other'
              }
              clearable
              searchable
              data={supportCategories
                ?.filter(
                  (supportCategory) =>
                    supportCategory.type === CategoryType.SUB_CATEGORY &&
                    supportCategory.sub_category_to_id === supportCategoryId
                )
                .map((category) => ({
                  value: category.name,
                  label: category.name,
                  key: category.id,
                }))}
              style={{
                overflow: 'visible',
              }}
              {...supportTicketForm.getInputProps('subCategory')}
            />
            {role !== Role.CUSTOMER && projects.length >= 1 && (
              <CottageSelect
                aria-label="Project"
                placeholder="Select a project"
                label="Project*"
                disabled={!supportTicketForm.values.category}
                clearable
                searchable
                data={projectsOptions}
                {...supportTicketForm.getInputProps('projectRecord')}
              />
            )}

            <CottageTextarea
              autosize
              minRows={2}
              disabled={!supportTicketForm.values.category}
              label="Description*"
              placeholder="Describe your issue"
              {...supportTicketForm.getInputProps('description')}
            />
            <Stack spacing="xxxxs">
              <Text fz="sm" color="blue.9">
                Upload File(s)
              </Text>
              <DocumentUpload
                acceptableTypesAsString="PDFs, ZIPs, JPGs, or PNGs (max 20mb)"
                acceptableMimeTypes={[
                  MIME_TYPES.pdf,
                  MIME_TYPES.png,
                  MIME_TYPES.jpeg,
                  MIME_TYPES.zip,
                ]}
                filesToUpload={supportFiles}
                setFilesToUpload={setSupportFiles}
                isSingleFile={false}
                modal
                documentType={DocumentType.SUPPORT}
                page={PAGES.SUPPORT_MODAL}
              />
            </Stack>
          </Stack>
        )}
      </BaseModal>
      <OkModal
        okButtonText="Done"
        onOk={() => {
          amplitude.track({
            event_type: ANALYTICS_EVENTS.SUPPORT_MODAL_CLOSED,
            event_properties: {
              modal: 'success',
            },
          });
          void onClose();
          setShowSuccessModal(false);
        }}
        opened={showSuccessModal}
        icon={<TbChecks />}
        title="Support ticket submitted!"
      >
        You&apos;ll receive an email from Cottage shortly confirming your
        request.
      </OkModal>
    </>
  );
};
