import { MIME_TYPE_TO_EXTENSION } from './constants/mimetypes';

export const capitalizeFirstLetter = (
  stringToCapitalize?: string
): string | undefined => {
  if (!stringToCapitalize) {
    return;
  }

  return (
    stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)
  );
};

export function getInitials(
  name: string | null | undefined,
  isViewerCustomerOrInternal: boolean
): string {
  if (!name) {
    return '';
  }
  const words = name.split(' ');
  const firstLetter = words[0].charAt(0).toUpperCase();
  if (isViewerCustomerOrInternal) {
    if (words.length === 1) return firstLetter;

    return (firstLetter + words[words.length - 1].charAt(0)).toUpperCase();
  }

  // For all other roles, return the first letter of the first word
  return firstLetter;
}

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
): string => {
  if (!firstName) {
    return '';
  }
  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

export const formatAmountInCents = (
  amount: number | undefined | null,
  rounded: boolean = false
): string => {
  if (amount === undefined || amount === null) {
    return '';
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: rounded ? 0 : 2,
    maximumFractionDigits: rounded ? 0 : 2,
  });

  return currencyFormatter.format(amount / 100);
};

export function generateRandomIdString(): string {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = 0; i < 12; i++) {
    if (i < 8) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    } else if (i === 8) {
      result += '-';
    } else {
      const randomIndex = Math.floor(Math.random() * 10);
      result += randomIndex;
    }
  }

  return result;
}

export function isDateTime(input: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  return regex.test(input);
}

// Converts fields that are a bigInt types to Date strings.
// This is because JSON does not support bigInt
export function convertBigIntsToDateStrings(obj: Record<string, unknown>) {
  for (const key in obj) {
    if (typeof obj[key] === 'bigint') {
      const bigIntAsNumber = Number(obj[key]);
      obj[key] = new Date(bigIntAsNumber);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      convertBigIntsToDateStrings(obj[key] as Record<string, unknown>);
    }
  }

  return obj;
}

// Converts an array of strings into a single string formatted with
// the Oxford comma convention (put', and ' before the last thing in list).
export const toOxfordCommaList = (arrayOfStrings: string[]): string => {
  const length = arrayOfStrings.length;
  if (length > 2) {
    return `${arrayOfStrings.slice(0, -1).join(', ')}, and ${
      arrayOfStrings[length - 1]
    }`;
  } else if (length === 2) {
    return `${arrayOfStrings[0]} and ${arrayOfStrings[1]}`;
  }

  return arrayOfStrings[0] || '';
};

export const splitName = (
  name: string
): { firstName: string; lastName: string } => {
  let firstName = '';
  let lastName = '';

  const trimmedName = name.trim();
  const nameParts = trimmedName.split(/\s+/);

  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length === 2) {
    firstName = nameParts[0];
    lastName = nameParts[1];
  } else if (nameParts.length > 2) {
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
  }

  return { firstName, lastName };
};

export const toTitleCase = (input: string) => {
  return input.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const convertToLowerRemoveWhitespace = (input: string) => {
  return input.toLowerCase().replace(/\s+/g, '');
};

export const getStringColSortableValue = (input: string | null | undefined) => {
  return input ? convertToLowerRemoveWhitespace(input) : '-';
};

export const isStringPartOfEnum = <T extends string>(
  str: string,
  enumObj: { [key: string]: T }
): str is T => Object.values(enumObj).includes(str as T);

export const getWordCount = (input?: string) => {
  if (!input) {
    return 0;
  }
  const words = input.trim().split(/\s+/);

  return words.length;
};

export const getExtensionFromMimeType = (mimeType: string) => {
  return MIME_TYPE_TO_EXTENSION[mimeType];
};

/**
 * Parses URL search query return values from useRouter to a string[].
 * @param queryInfo
 * @returns [] if queryInfo is null, [queryInfo] if it's a string, and queryInfo otherwise
 */
export const convertQueryParamToStringArray = (
  queryInfo: string[] | string | undefined
): string[] => {
  let parsedQueryInfo: string[];
  if (!queryInfo) {
    parsedQueryInfo = [];
  } else if (!Array.isArray(queryInfo)) {
    parsedQueryInfo = [queryInfo];
  } else {
    parsedQueryInfo = queryInfo;
  }

  return parsedQueryInfo;
};

export const prependIndefiniteArticle = (word: string): string => {
  word = word.trim();
  const firstLetter = word[0].toLowerCase();
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  if (vowels.includes(firstLetter)) {
    return 'an ' + word;
  }

  return 'a ' + word;
};

export const removeHttps = (url: string) => {
  const prefix = 'https://';
  if (url.startsWith(prefix)) {
    return url.slice(prefix.length);
  }
  return url;
};
