import { DocumentCategory, Prisma } from '@prisma/client';

export type FileInfo = {
  mimeType: string;
  name: string;
};

export type Document = {
  mime_type: string | null;
  name: string;
  uuid: string;
  project_id?: number | null;
  version?: number;
};

export type PgDocument = Prisma.documentsGetPayload<typeof DocumentSelect>;

export const DocumentSelect = {
  select: {
    id: true,
    name: true,
    uuid: true,
    version: true,
    project_id: true,
    mime_type: true,
    category: true,
    created_at: true,
  },
};

// We have to convert created_at from BigInt to Date string in our server
// responses, otherwise JSON will error.
export type PgDocumentResponse = Prisma.documentsGetPayload<
  typeof DocumentWithAuthor
>;

export const DocumentWithAuthor =
  Prisma.validator<Prisma.documentsDefaultArgs>()({
    include: {
      customer: {
        select: {
          full_name: true,
        },
      },
      user: {
        select: {
          first_name: true,
          last_name: true,
          roles: {
            select: {
              name: true,
            },
          },
          companies: {
            select: {
              name: true,
              users: {
                select: {
                  id: true,
                },
              },
            },
          },
        },
      },
      internal: {
        select: {
          full_name: true,
        },
      },
    },
  });

// TODO (COT-2836): Remove this enum
export enum DocumentType {
  CHANGE_ORDER = 'Change Order',
  PHOTO = 'Photo',
  PROPOSAL = 'Proposal',
  PROPOSAL_CONTRACT_AGREEMENT = 'Proposal Contract Agreement',
  PURCHASE_ORDER = 'Purchase Order',
  SUPPORT = 'Support',
  WORK_ORDER = 'Work Order',
}

export enum UploadType {
  SUPPORT,
  IMAGE,
  ENTITY_DOCUMENT,
}

export type DocumentUploadUrlInfo = {
  url: string;
  uuid: string;
};

export type DocumentViewUrlInfo = {
  url: string;
};

export type DoToLambdaFile = {
  digitalOceanName: string;
  originalName: string;
  mimetype?: string;
};

export type DownloadDocuments = {
  presignedUrl: string;
  fileName?: string;
};

/** Requests & Responses */

export type DownloadUrlsRequest = {
  documents: Document[];
  expiresIn: number;
  uploadType: UploadType;
};

export type StreamDoToGDriveRequest = {
  files: DoToLambdaFile[];
  projectUuid: string;
  serviceTypeName: string;
  consultantDocFolderUrl?: string | null;
  gcDocFolderUrl?: string | null;
};

export type StreamDoToGDriveResponse = {
  message: string;
  uploadedFiles: string[];
  errors?: string[];
  warning?: string;
};

export type UploadUrlsRequest = {
  files: FileInfo[];
  uploadType: UploadType;
  originalUuid?: string;
};

export type CreateDocumentRequest = {
  companiesToNotify: number[];
  digitalOceanDocument: Document[];
  documentCategory: DocumentCategory;
  fileName: string;
  mime_type: string | null;
  shouldNotifyCustomers: boolean;
  // If here, we should create a new version of an existing document. Otherwise,
  // allow the backend to generate the UUID.
  uuid?: string;
};

export type UpdateDocumentRequest = {
  newActiveVersion?: number;
  newArchived?: boolean;
  newName?: string;
};
