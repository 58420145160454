import { Group, Stack, Text } from '@mantine/core';
import { CompanyType, StaticAttribute } from '@prisma/client';
import {
  TbAward,
  TbCertificate,
  TbMap2,
  TbRotateDot,
  TbSofa,
  TbTools,
  TbTrophy,
  TbVideo,
} from 'react-icons/tb';

import { CottageTooltip } from '@/components/shared/default';
import { Company } from '@/types/api';
import { ProposalCompany } from '@/types/api/proposals';

type AttributeInfo = {
  text: string;
  icon?: JSX.Element;
};

type CompanyAttributeProps = {
  company: ProposalCompany | Company;
  limit?: number;
  projectMunicipality?: string | null;
};

export const staticAttributes: Record<StaticAttribute, AttributeInfo> = {
  [StaticAttribute.LICENSED_ARCHITECT]: {
    icon: <TbCertificate size={24} color="blue.9" />,
    text: 'Licensed Architect',
  },
  [StaticAttribute.DESIGN_BUILD]: {
    icon: <TbTools size={24} color="blue.9" />,
    text: 'Design-Build',
  },
  [StaticAttribute.REMOTE_DESIGNER]: {
    icon: <TbVideo size={24} color="blue.9" />,
    text: 'Remote Designer',
  },
  [StaticAttribute.FULL_SERVICE]: {
    icon: <TbRotateDot size={24} color="blue.9" />,
    text: 'Full Service',
  },
  [StaticAttribute.AWARD_WINNING]: {
    icon: <TbAward size={24} color="blue.9" />,
    text: 'Award Winning',
  },
  [StaticAttribute.INTERIOR_DESIGNER]: {
    icon: <TbSofa size={24} color="blue.9" />,
    text: 'Interior Designer',
  },
};

export const ExperiencedMunicipalityAttribute = ({
  company,
  projectMunicipality,
}: {
  company: ProposalCompany | Company;
  projectMunicipality?: string;
}) => {
  if (!projectMunicipality) return null;

  return company?.company_derived_data_cache?.experienced_municipalities?.includes(
    projectMunicipality
  ) ? (
    <Group noWrap spacing="xxs" key={`experiencedMunicipality ${company.id}`}>
      <TbMap2 size={24} color="blue.9" />
      <Text c="blue.9" style={{ cursor: 'default' }}>
        Experienced in your municipality
      </Text>
    </Group>
  ) : null;
};

/**
 * Displays the number of completed projects, or null if the count is less than
 * 5.
 */
export const CompletedProjectsAttribute = ({
  count,
  companyType,
}: {
  companyType?: CompanyType;
  count?: number;
}) => {
  if (!count) return null;
  let countText;

  // 30+ (Shown as 30+ in the Blueprint UI)
  if (count >= 30) {
    countText = '30+ completed projects';
  }
  // 20-29 (Shown as 20+ in the Blueprint UI)
  else if (count >= 20) {
    countText = '20+ completed projects';
  }
  // 10-19 (Shown as 10+ in the Blueprint UI)
  else if (count >= 10) {
    countText = '10+ completed projects';
  }
  // 5-9 (Shown as 5+ in the Blueprint UI)
  else if (count >= 5) {
    countText = '5+ completed projects';
  }
  // Return nothing if the count is less than 5.
  else if (count < 5) {
    return null;
  }

  const tooltip =
    companyType === CompanyType.ARCHITECT
      ? 'Number of projects that this designer has designed and permitted for real clients.'
      : companyType === CompanyType.GC
        ? 'Number of projects that this contractor has completed for real clients.'
        : undefined;

  return (
    <>
      {count && count >= 5 && (
        <Group spacing="xxs" noWrap>
          <TbTrophy color="blue.9" size={24} />
          <CottageTooltip label={tooltip} w={200} disabled={!tooltip}>
            <Text c="blue.9" style={{ cursor: 'default' }}>
              {countText}
            </Text>
          </CottageTooltip>
        </Group>
      )}
    </>
  );
};

export const DynamicCompanyAttributes = ({
  company,
  projectMunicipality,
}: {
  company: ProposalCompany | Company;
  projectMunicipality?: string;
}) => {
  return (
    <>
      <ExperiencedMunicipalityAttribute
        company={company}
        projectMunicipality={projectMunicipality}
      />
      {/* TODO COT-3651: Put this back */}
      {/* <CompletedProjectsAttribute
        companyType={company?.company_type}
        count={company?.company_derived_data_cache?.projects_completed}
      /> */}
    </>
  );
};

/**
 * Displays company attributes in order: experienced municipality, completed
 * projects, and other static attributes.
 * @param limit - limit the number of displayed attributes
 * @param projectMunicipality - the municipality of the project. If company
 * has experience in that municipality, it will be displayed.
 */
export const CompanyAttributes = ({
  company,
  limit,
  projectMunicipality,
}: CompanyAttributeProps) => {
  const displayedAttributes = [];

  if (
    projectMunicipality &&
    company?.company_derived_data_cache?.experienced_municipalities
  ) {
    displayedAttributes.push(
      <ExperiencedMunicipalityAttribute
        company={company}
        projectMunicipality={projectMunicipality}
      />
    );
  }

  const projectCount = company?.company_derived_data_cache?.projects_completed;

  if (!!projectCount && projectCount >= 5) {
    // TODO (COT-3651): Put this back
    // displayedAttributes.push(
    //   <CompletedProjectsAttribute
    //     count={projectCount}
    //     companyType={company.company_type}
    //     key={`projectCount ${company.id}`}
    //   />
    // );
  }

  if (company?.static_attributes) {
    const otherStaticAttributes = company.static_attributes
      ?.map(
        (tag) =>
          staticAttributes[tag] && (
            <Group noWrap key={tag} spacing="xxs">
              {staticAttributes[tag]?.icon}
              <Text c="blue.9" style={{ cursor: 'default' }}>
                {staticAttributes[tag]?.text}
              </Text>
            </Group>
          )
      )
      .filter((e) => e !== undefined);

    displayedAttributes.push(...otherStaticAttributes);
  }

  return (
    <Stack spacing="sm">
      {displayedAttributes.slice(0, limit ?? displayedAttributes.length)}
    </Stack>
  );
};
