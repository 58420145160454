import * as amplitude from '@amplitude/analytics-browser';
import {
  createStyles,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { signOut, useSession } from 'next-auth/react';
import { TbChevronRight, TbUserCircle } from 'react-icons/tb';

import { ANALYTICS_EVENTS } from '@/lib/constants/analyticsEvents';
import useCompany from '@/lib/hooks/useCompany';
import { useCottageFlags } from '@/lib/hooks/useCottageFlags';
import { validateRoles } from '@/lib/roleUtils';

import { CompanyProfileQuickView } from '../company/CompanyProfileQuickView';
import ProfileAvatar from '../shared/ProfileAvatar';

export enum UserMenuCompactDisplayStrategy {
  FULL_WIDTH,
  ICON_ONLY,
}

const useMobileStyles = createStyles((theme) => ({
  userInfo: {
    justifySelf: 'flex-start',
    marginTop: 'auto',
    padding: theme.spacing.md,
    width: '100%',
    borderTop: `1px solid ${theme.colors.gray[1]}`,
    '&:hover': {
      background: theme.colors.blue[0],
    },
  },
}));

type UserMenuProps = {
  displayStrategy: UserMenuCompactDisplayStrategy;
  userEmail: string;
  userName: string;
};

export default function UserMenu({
  userEmail,
  userName,
  displayStrategy,
}: UserMenuProps) {
  const { doubleOptIn: doubleOptInFlag } = useCottageFlags();
  const { data: session } = useSession({ required: true });
  const { isArchitect } = validateRoles(session?.user?.roles ?? []);
  const { company } = useCompany();
  const { classes: mobileClasses } = useMobileStyles();
  const [
    openedProfileQuickView,
    { open: openProfileQuickView, close: closeProfileQuickView },
  ] = useDisclosure(false);

  const containerClasses =
    displayStrategy === UserMenuCompactDisplayStrategy.FULL_WIDTH
      ? mobileClasses.userInfo
      : '';

  return (
    <Stack className={containerClasses}>
      <Menu
        position="bottom-end"
        shadow="0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)"
        styles={(theme) => ({
          itemIcon: {
            color: theme.colors.actionActive,
          },
          itemLabel: {
            color: theme.colors.textPrimary,
          },
        })}
      >
        <Menu.Target>
          <UnstyledButton
            onClick={() => {
              amplitude.track({
                event_type: ANALYTICS_EVENTS.PROFILE_ICON_CLICKED,
              });
            }}
          >
            {displayStrategy === UserMenuCompactDisplayStrategy.FULL_WIDTH ? (
              <Flex gap="xs" align="center">
                <Group style={{ flexGrow: 1 }}>
                  <ProfileAvatar userName={userName} />
                  <Stack spacing="xxxs">
                    <Text fw="600">{userName}</Text>
                    <Text c="gray.6">{userEmail}</Text>
                  </Stack>
                </Group>
                <TbChevronRight />
              </Flex>
            ) : (
              <ProfileAvatar userName={userName} />
            )}
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          {displayStrategy === UserMenuCompactDisplayStrategy.ICON_ONLY && (
            <>
              <Menu.Item disabled>
                <Text c="textPrimary.0">{userName}</Text>
                <Text c="textSecondary.0">{userEmail}</Text>
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {doubleOptInFlag && isArchitect && (
            <>
              <Menu.Item
                onClick={() => {
                  openProfileQuickView();
                }}
              >
                <Group>
                  <TbUserCircle size={24} />
                  <Text>View Profile</Text>
                </Group>
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            onClick={() => {
              void signOut({ callbackUrl: '/auth/signin' }).then(() => {
                amplitude.track({
                  event_type: ANALYTICS_EVENTS.SIGNED_OUT,
                });
                amplitude.reset();
              });
            }}
          >
            Log Out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {company && (
        <CompanyProfileQuickView
          company={company}
          onClose={closeProfileQuickView}
          opened={openedProfileQuickView}
        />
      )}
    </Stack>
  );
}
