import useSWRImmutable from 'swr/immutable';

import { SupportCategory } from '@/types/api';

import { createGetFetcher } from '../api/client/fetchers';

const get = createGetFetcher('support categories');

export const useSupportCategories = () => {
  const { data, isLoading, error } = useSWRImmutable<SupportCategory[], Error>(
    '/api/support/categories',
    get
  );

  return {
    supportCategories: data,
    loading: isLoading,
    error,
  };
};
