import {
  ActionIcon,
  AspectRatio,
  Center,
  createStyles,
  getStylesRef,
  Image as MantineImage,
  MantineNumberSize,
  Paper,
} from '@mantine/core';
import { MediaType } from '@prisma/client';
import Script from 'next/script';
import React from 'react';
import { TbPlayerPlay } from 'react-icons/tb';

import { Media } from '@/types/api';

import { CottageModal } from './CottageModal';

// Used for play icon hover effects
const useStyles = createStyles((theme) => ({
  addIcon: {
    ref: getStylesRef('addIcon'),
    background: '#10111380',
    backdropFilter: 'blur(4px)',
  },
  outerDiv: {
    '&:hover': {
      cursor: 'pointer',
    },
    // reference button with nested selector
    [`&:hover .${getStylesRef('addIcon')}`]: {
      background: theme.colors.blue[9],
      backdropFilter: 'unset',
    },
  },
}));

export const VideoIframe = ({ media }: { media: Media }) => {
  return (
    <AspectRatio ratio={1920 / 1080} maw={'100%'}>
      <iframe
        src={`${media.embedUrl}&autoplay=1`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title={media.name}
      ></iframe>
      <Script src="https://player.vimeo.com/api/player.js"></Script>
    </AspectRatio>
  );
};

export const MediaModal = ({
  media,
  opened,
  onClose,
  size,
}: {
  onClose: () => void;
  opened: boolean;
  size: MantineNumberSize;
  media?: Media;
}) => {
  return (
    <CottageModal
      opened={opened}
      onClose={onClose}
      centered
      size={size}
      withCloseButton
    >
      {media && media.media_type === MediaType.VIDEO && (
        <VideoIframe media={media} />
      )}
      {media && media.media_type === MediaType.IMAGE && (
        <MantineImage
          src={media.url}
          alt="Project Image"
          withPlaceholder
          fit="contain"
          height="75vh"
        />
      )}
    </CottageModal>
  );
};

/**
 * Given a video media, displays the thumbnail. If an onClick function is
 * specified, displays the play button.
 * @param
 * @returns
 */
export const VideoThumbnail = ({
  media,
  onClick,
}: {
  media: Media;
  onClick?: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Paper
      p="xl"
      radius="sm"
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${media.thumbnailUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      data-testid="company-media"
    >
      <Center
        h="100%"
        w="100%"
        onClick={() => onClick?.()}
        className={classes.outerDiv}
      >
        {onClick && (
          <ActionIcon
            size="xl"
            radius="xl"
            className={classes.addIcon}
            variant="filled"
          >
            <TbPlayerPlay />
          </ActionIcon>
        )}
      </Center>
    </Paper>
  );
};
