import { CompanyType, CreatedBy } from '@prisma/client';

import { UserType } from '@/components/shared/types';

export enum Role {
  ARCHITECT = 'architect',
  COMPANY_OWNER = 'company_owner',
  CONSULTANT = 'consultant',
  CUSTOMER = 'customer',
  GC = 'gc',
  /**
   * @deprecated use Role.CUSTOMER instead. Maintaining until all old sessions
   * with this role assigned have been removed and reassigned to the CUSTOMER
   * role.
   */
  HOMEOWNER = 'homeowner',
  INTERNAL = 'internal',
  NONE = 'none',
  PROJECT_OWNER = 'project_owner',
  SUPPLIER = 'supplier',
}

export const ROLE_TITLE: Record<Role, string> = Object.freeze({
  architect: 'Designer',
  company_owner: 'Company Owner',
  consultant: 'Consultant',
  customer: 'Homeowner',
  gc: 'General Contractor',
  homeowner: 'Homeowner',
  internal: 'Internal',
  none: 'None',
  project_owner: 'Project Owner',
  supplier: 'Supplier',
});

export const COMPANY_TYPE_TO_ROLE: Record<CompanyType, Role> = Object.freeze({
  ARCHITECT: Role.ARCHITECT,
  CONSULTANT: Role.CONSULTANT,
  GC: Role.GC,
  SUPPLIER: Role.SUPPLIER,
});

export const ROLE_TO_USER_TYPE: Partial<Record<Role, UserType>> = {
  [Role.ARCHITECT]: UserType.ARCHITECT,
  [Role.CONSULTANT]: UserType.CONSULTANT,
  [Role.CUSTOMER]: UserType.CUSTOMER,
  [Role.GC]: UserType.GC,
  [Role.INTERNAL]: UserType.INTERNAL,
};
export const ROLE_TO_COMPANY_TYPE: Partial<Record<Role, CompanyType>> =
  Object.freeze({
    [Role.ARCHITECT]: CompanyType.ARCHITECT,
    [Role.CONSULTANT]: CompanyType.CONSULTANT,
    [Role.GC]: CompanyType.GC,
    [Role.SUPPLIER]: CompanyType.SUPPLIER,
  });

export const ROLE_TO_CREATED_BY: Record<Role, CreatedBy | undefined> = {
  [Role.ARCHITECT]: CreatedBy.ARCHITECT,
  [Role.COMPANY_OWNER]: undefined,
  [Role.CONSULTANT]: CreatedBy.CONSULTANT,
  [Role.CUSTOMER]: CreatedBy.CUSTOMER,
  [Role.GC]: CreatedBy.GC,
  [Role.HOMEOWNER]: undefined,
  [Role.INTERNAL]: CreatedBy.INTERNAL,
  [Role.NONE]: undefined,
  [Role.PROJECT_OWNER]: undefined,
  [Role.SUPPLIER]: undefined,
};

export const COMPANY_ROLES = new Set([
  Role.ARCHITECT,
  Role.CONSULTANT,
  Role.GC,
  Role.SUPPLIER,
]);

export const ALLOWED_PRO_REQUESTER_ROLES = Object.freeze([
  Role.ARCHITECT,
  Role.CUSTOMER,
  Role.GC,
  Role.INTERNAL,
] as const);

export type AllowedProRequesterRole =
  (typeof ALLOWED_PRO_REQUESTER_ROLES)[number];

// Maps requester user role to list of pro roles that they can invite to a project
// e.g. A designer can only request a consultant or GC to be invited to a project
export const REQUESTER_ROLE_TO_ALLOWED_REQUESTED_ROLES: Record<
  AllowedProRequesterRole,
  Role[]
> = Object.freeze({
  [Role.ARCHITECT]: [Role.CONSULTANT, Role.GC],
  [Role.CUSTOMER]: [Role.CONSULTANT, Role.GC],
  [Role.GC]: [Role.CONSULTANT],
  [Role.INTERNAL]: [Role.CONSULTANT, Role.GC],
});
