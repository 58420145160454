import { MantineTheme, MantineThemeOverride } from '@mantine/core';
import localFont from 'next/font/local';

const GoodSansLight = localFont({
  src: '../../public/fonts/GoodSans/GoodSans-Regular.woff2',
});

export const cottageTheme = (): MantineThemeOverride => ({
  colors: {
    blue: [
      '#F6F8FA', // blue.0
      '#EDF0F5', // blue.1
      '#E4E9EF', // blue.2
      '#BCC8D8', // blue.3
      '#A3A9B6', // blue.4
      '#6E778C', // blue.5
      '#54647B', // blue.6
      '#21477D', // blue.7
      '#2C3959', // blue.8
      '#0D1C40', // blue.9
    ],
    green: [
      '#F2F8F3', // green.0
      '#D3F9D8', // green.1
      '#B2F2BB', // green.2
      '#8CE99A', // green.3
      '#69DB7C', // green.4
      '#51CF66', // green.5
      '#40C057', // green.6
      '#388E3C', // green.7
      '#1B5E20', // green.8
      '#4A5426', // green.9
    ],
    orange: [
      '#FBF8F5', // orange.0
      '#FFF8F4', // orange.1
      '#FFEBE1', // orange.2
      '#FFDFD0', // orange.3
      '#FAC4AA', // orange.4
      '#F0A884', // orange.5
      '#EA8D5E', // orange.6
      '#E8753B', // orange.7
      '#CE6129', // orange.8
      '#853800', // orange.9
    ],
    yellow: [
      '#FFFCF6', // yellow.0
      '#FFF3BF', // yellow.1
      '#FFEC99', // yellow.2
      '#FFE066', // yellow.3
      '#FFD43B', // yellow.4
      '#EDB81C', // yellow.5
      '#E5C9AB', // yellow.6
      '#F59F00', // yellow.7
      '#F08C00', // yellow.8
      '#E67700', // yellow.9
    ],
    violet: ['#F4F2F9', '#5F3DC4'],
    forestGreen: ['#F4F4F2', '#4A5426'],
    white: ['#FFFFFF'],
    actionActive: ['rgba(0, 0, 0, 0.54)'],
    gradients: [
      'linear-gradient(128.23deg, #FFF3ED -7.55%, #F3F0FF 124.79%)',
      'linear-gradient(93deg, #FFF3ED 4.83%, #F3F0FF 105.06%)',
      'linear-gradient(93deg, #E8F5E9 4.83%, #F4FCE3 105.06%)',
      'linear-gradient(128deg, #FFF3ED -7.55%, #F3F0FF 124.79%)',
      'linear-gradient(0deg, #FFFCF6, #FFFCF6)',
    ],
    ivory: ['#FFFCF6', '#E5C9AB', '#E5C9AB32'],
    neutral: ['#FCFCFD', '#FCF9F3'],
  },
  primaryColor: 'blue',
  primaryShade: 8,
  fontFamily: GoodSansLight.style.fontFamily,
  globalStyles: () => ({
    body: {
      ...{ fontSize: '14px' },
    },
  }),
  headings: {
    fontFamily: GoodSansLight.style.fontFamily,
    fontWeight: 700,
  },
  fontSizes: {
    xxl: '32px',
  },
  spacing: {
    xxxxs: '2px',
    xxxs: '4px',
    xxs: '8px',
    xxl: '32px',
    xxxl: '40px',
  },
  components: {
    Avatar: {
      variants: {
        fun: (theme) => ({
          root: {
            background: theme.colors.gradients[1],
          },
        }),
      },
    },
    Badge: {
      styles: () => ({
        root: {
          maxWidth: 'max-content',
        },
      }),
      variants: {
        blue: (theme) => ({
          root: {
            backgroundColor: theme.colors.blue[7],
            color: 'white',
          },
        }),
        blueDark: (theme) => ({
          root: {
            backgroundColor: theme.colors.blue[9],
            color: 'white',
          },
        }),
        brown: (theme) => ({
          root: {
            backgroundColor: theme.colors.orange[9],
            color: 'white',
          },
        }),
        gray: (theme) => ({
          root: {
            backgroundColor: theme.colors.blue[4],
            color: 'white',
          },
        }),
        olive: (theme) => ({
          root: {
            backgroundColor: theme.colors.green[9],
            color: 'white',
          },
        }),
      },
    },
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.blue[9],
          '&:first-of-type': {
            color: theme.colors.blue[8],
          },
        },
      }),
    },
    Button: {
      defaultProps: () => ({
        border: '0px',
      }),
      variants: {
        default: () => ({
          inner: {
            color: 'rgba(13, 28, 64, 0.87)',
          },
        }),
      },
      // Subscribe to theme and component params
      styles: (theme: MantineTheme, params: { size: string }) => ({
        label: {
          ...(params.size === 'xl' && {
            padding: '0 16px',
            [theme.fn.smallerThan('lg')]: {
              padding: '0',
            },
          }),
        },
        root: {
          fontWeight: 'bold',
        },
      }),
    },
    Checkbox: {
      styles: {
        root: {
          padding: '4px 0px',
        },
      },
    },
    InputWrapper: {
      defaultProps: {
        styles: (theme: MantineTheme) => ({
          label: { color: theme.colors.blue[9] },
        }),
      },
    },
    List: {
      defaultProps: (theme: MantineTheme) => ({
        color: theme.colors.blue[9],
      }),
      styles: (theme: MantineTheme) => ({
        item: {
          color: theme.colors.blue[9],
        },
      }),
    },
    Modal: {
      styles: (theme: MantineTheme) => ({
        title: {
          color: theme.colors.blue[9],
        },
      }),
    },
    Select: {
      styles: (theme: MantineTheme) => ({
        label: { color: theme.colors.blue[9] },
        description: { color: theme.colors.blue[6] },
      }),
    },
    Slider: {
      styles: (theme: MantineTheme) => ({
        bar: {
          background: theme.colors.blue[8],
        },
        thumb: {
          background: theme.colors.blue[8],
          border: theme.colors.white,
        },
      }),
    },
    Tabs: {
      defaultProps: (theme: MantineTheme) => ({
        color: theme.colors.blue[9],
      }),
      styles: (theme: MantineTheme) => ({
        tabLabel: {
          color: theme.colors.blue[9],
        },
      }),
    },
    Text: {
      defaultProps: (theme: MantineTheme) => ({
        color: theme.colors.blue[9],
      }),
      variants: {
        headerXxl: (theme) => ({
          root: {
            fontSize: theme.fontSizes.xxl,
            fontWeight: 700,
            [`@media (max-width: ${theme.breakpoints.sm})`]: {
              fontSize: theme.fontSizes.xl,
            },
          },
        }),
        headerXl: (theme) => ({
          root: {
            fontSize: theme.fontSizes.xl,
            fontWeight: 700,
            [`@media (max-width: ${theme.breakpoints.sm})`]: {
              fontSize: theme.fontSizes.lg,
            },
          },
        }),
        headerLg: (theme) => ({
          root: {
            fontSize: theme.fontSizes.lg,
            fontWeight: 700,
          },
        }),
        headerMd: (theme) => ({
          root: {
            fontSize: theme.fontSizes.md,
            fontWeight: 700,
            [`@media (max-width: ${theme.breakpoints.sm})`]: {
              fontSize: theme.fontSizes.sm,
            },
          },
        }),
        subtitle: (theme) => ({
          root: {
            color: theme.colors.blue[6],
          },
        }),
        alert: (theme) => ({
          root: {
            color: theme.colors.red[8],
          },
        }),
      },
    },
    ThemeIcon: {
      variants: {
        default: () => ({
          root: {
            border: 'none',
          },
        }),
        laurel: (
          theme: MantineTheme,
          params,
          context: { size?: string | number | undefined }
        ) => ({
          root: {
            border: 'none',
            backgroundColor: theme.colors.ivory[0],
            // Default lg size
            minHeight: '1.625rem',
            minWidth: '1.625rem',
            height: '1.625rem',
            width: '1.625rem',
            // Number sizes
            ...(typeof context.size === 'number' && {
              minHeight: `${context.size}px`,
              minWidth: `${context.size}px`,
              height: `${context.size}px`,
              width: `${context.size}px`,
            }),
            ...(context.size === 'xl' && {
              minHeight: '2rem',
              minWidth: '2rem',
              height: '2rem',
              width: '2rem',
            }),
            ...(context.size === 'md' && {
              minHeight: '1.25rem',
              minWidth: '1.25rem',
              height: '1.25rem',
              width: '1.25rem',
            }),
            ...(context.size === 'sm' && {
              minHeight: '1rem',
              minWidth: '1rem',
              height: '1rem',
              width: '1rem',
            }),
          },
        }),
      },
    },
    UnstyledButton: {
      styles: (theme: MantineTheme) => ({
        root: {
          [`@media (max-width: ${theme.breakpoints.sm})`]: {
            fontSize: theme.fontSizes.sm,
          },
        },
      }),
    },
  },
});
