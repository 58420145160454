export enum PageType {
  PROPOSAL,
  WORK_ORDER,
}

export enum UserType {
  ARCHITECT,
  CONSULTANT,
  CUSTOMER,
  GC,
  INTERNAL,
}

export enum ValueEngineeringViewType {
  // GC can edit the value engineering items and note.
  EDIT_GC,
  // GC can only view the value engineering items and note.
  VIEW_ONLY_GC,
  // GC can only view the customer's accepted or declined value engineering
  // items and/or their change request note.
  CHANGE_REQUESTED_VIEW_ONLY_GC,
  // Customer can accept or decline the value engineering items.
  EDIT_CUSTOMER,
  // Customer can only view the value engineering items and/or value engineering
  // note.
  VIEW_ONLY_CUSTOMER,
  // Customer can only view their accepted or declined value engineering items
  // and/or their change request note.
  CHANGE_REQUESTED_VIEW_ONLY_CUSTOMER,
}

export interface UpsellType {
  subtitle: string;
  title: string;
  ctaText?: string;
}

export const UpsellTypes: Record<string, UpsellType> = {
  LANDSCAPE: {
    title: 'Get the perfect Landscaping to complement your project!',
    subtitle:
      'Request multiple quotes from local Landscaping Design & Installation providers.',
    ctaText: 'Receive Proposal',
  },
  SOLAR: {
    title: 'Go Solar and reduce your utility bills!',
    subtitle:
      'Request multiple quotes from local Solar & Electrification providers. Services include ADU solar, main home solar, EV charging, and more.',
    ctaText: 'Receive Proposal',
  },
  UTILITY: {
    title:
      'Save months of headache by expediting your electrical panel upgrade!',
    subtitle:
      'Hire a utility consultant to save you months in utility permitting and hours of tedious labor.',
    ctaText: 'Get Offers',
  },
  SUPPLIER_WINDOWS_DOORS_HO: {
    title:
      "It's likely time for your General Contractor to buy Windows & Doors!",
    subtitle:
      "Ask them about Cottage's supplier network to get discounts on materials.",
  },
  SUPPLIER_FINISHES_HO: {
    title:
      "It's likely time for your General Contractor to buy Interior Finish Materials!",
    subtitle:
      "Ask them about Cottage's supplier network to get discounts on materials.",
  },
  SUPPLIER_WINDOWS_DOORS_GC: {
    title: "It's time to procure Windows & Doors for your project!",
    subtitle:
      "Leverage Cottage's supplier network to get responsive service and discounts on materials.",
    ctaText: 'Request Quotes',
  },
  SUPPLIER_FINISHES_GC: {
    title: "It's time to procure Finish Materials for your project!",
    subtitle:
      "Ask them about Cottage's supplier network to get discounts on materials.",
    ctaText: 'Request Quotes',
  },
};
