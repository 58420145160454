import { Box, Group, ScrollArea, Stack } from '@mantine/core';
import { Property } from 'csstype';
import React, { PropsWithChildren, useContext, useState } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';
import { DisplaySize, SizeContext } from '../contexts/SizeContext';
import { CottageModal } from '../default';

export enum ModalType {
  STANDARD,
  WIDE,
}

type TwoColumnModalBaseProps = {
  modalType: ModalType;
  opened: boolean;
  allowReopen?: boolean;
  wrap?: Property.FlexWrap;
} & PropsWithChildren;

type WithCloseProps = {
  onClose: () => void;
  withClose: boolean;
} & TwoColumnModalBaseProps;

type WithoutCloseProps = {
  onClose?: never;
  withClose?: never;
} & TwoColumnModalBaseProps;

type TwoColumnModalProps = WithCloseProps | WithoutCloseProps;

/**
 * This is a modal with a standard grid column layout. It is meant to be used
 * with two child components: TwoColumnModal.Media and TwoColumnModal.Text.
 *
 * @param {ModalType} modalType - determines modal sizing. Defaults to
 * ModalType.STANDARD, which is the standard size of our announcement modals.
 * @param onClose - callback to close modal
 * @param withClose - whether to show the close button in the top right corner
 * @param opened - whether the modal is open
 * @param allowReopen - whether the modal can be reopened after closing
 * @param wrap - optional, flex-wrap property for the Grid component
 *
 * @returns Modal with grid layout
 */
export const TwoColumnModal = function ({
  modalType = ModalType.STANDARD,
  children,
  onClose,
  withClose = false,
  opened,
  allowReopen = false,
  wrap = 'wrap',
}: TwoColumnModalProps) {
  const [closed, setClosed] = useState<boolean>(false);

  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  const isWideModal = modalType === ModalType.WIDE;

  const handleClose = () => {
    onClose?.();
    if (!allowReopen) {
      setClosed(true);
    }
  };

  return (
    <SizeContext.Provider
      value={{
        size: isWideModal ? DisplaySize.DEFAULT : DisplaySize.COMPACT,
      }}
    >
      <CottageModal
        opened={!closed && opened}
        onClose={handleClose}
        size={isWideModal ? 921 : 'xl'}
        padding={0}
        withCloseButton
        closeButtonProps={{
          iconSize: 24,
          my: 8,
          mr: 8,
          style: {
            visibility: withClose ? 'visible' : 'hidden',
          },
        }}
      >
        <Group
          // Top margin is handled by the Modal header
          grow
          mx={isMobile ? 'md' : 'xl'}
          mb={isMobile ? 'md' : 'xl'}
          align="flex-start"
          style={{
            flexWrap: wrap,
            height: isMobile ? 'fit-content' : '425px',
          }}
        >
          {children}
        </Group>
      </CottageModal>
    </SizeContext.Provider>
  );
};

type TwoColumnModalColProps = {
  paneColor?: string;
  paneDisplayedRight?: boolean;
} & PropsWithChildren;

TwoColumnModal.Media = function MediaCol({
  children,
  paneColor,
}: TwoColumnModalColProps) {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  return (
    <Box
      pos={isMobile ? 'initial' : 'sticky'}
      bg={paneColor}
      data-testid="grid-col"
      style={{
        height: 'fit-content',
        minHeight: 'fit-content',
      }}
    >
      {children}
    </Box>
  );
};

type TwoColumnModalTextProps = {
  actionButton?: React.ReactNode;
} & PropsWithChildren &
  TwoColumnModalColProps;

TwoColumnModal.Text = function TextCol({
  actionButton,
  children,
  paneColor,
}: TwoColumnModalTextProps) {
  const { size } = useContext(SizeContext);
  const isCompact = size === DisplaySize.COMPACT;

  return (
    <ScrollArea
      bg={paneColor}
      data-testid="grid-col"
      style={{
        height: '100%',
      }}
      offsetScrollbars
    >
      <Stack>
        <div style={{ flex: 1, alignContent: 'center' }}>
          <Stack spacing={isCompact ? 'sm' : 'xl'}>{children}</Stack>
        </div>
        {actionButton}
      </Stack>
    </ScrollArea>
  );
};
